import { Paper, Table, TableBody, TableCell, TableRow, Typography } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

import React from 'react';
const Config = [
  { k: 'Effort', t: 'Amount of Effort:' },
  { k: 'Course', t: 'Course Type:' },
  { k: 'Temp', t: 'Serving Temp:' },
  { k: 'Group', t: 'Recipe Group:' },
  { k: 'Time', t: 'Cooking Time:' },
  { k: 'Cuisine', t: 'Cuisine:' },
  { k: 'Serves', t: 'Number Served:' },
  { k: 'Comment', t: 'Recipe Source:' },
  { k: 'Ingredients', t: 'Ingredients:', pre: true },
  { k: 'Directions', t: 'Directions:', pre: true },
];

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(1),
    },
    name: {
      minWidth: '10rem',
      padding: 3,
    },
    info: {
      padding: 3,
    },
  })
);
const Recipe = (props: { recipe: any }) => {
  const classes = useStyles();
  const { recipe } = props;
  if (!recipe) {
    return null;
  }
  return (
    <Paper className={classes.root}>
      <Typography variant="h5" align="left">
        {recipe.Name}
      </Typography>
      <Table>
        <TableBody>
          {Config.map((item, index) => (
            <TableRow key={`${recipe.Name}-${index}`}>
              <TableCell className={classes.name}>{item.t}</TableCell>
              <TableCell className={classes.info}>
                {item.pre ? (
                  <pre>{String(recipe[item.k] && (recipe[item.k].string || recipe[item.k].text))}</pre>
                ) : (
                  <Typography>{String(recipe[item.k])}</Typography>
                )}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Paper>
  );
};
export default Recipe;
