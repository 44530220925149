import { Grid } from '@material-ui/core';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import React, { useMemo, useState } from 'react';
import { FixedSizeList, ListChildComponentProps } from 'react-window';
import Recipes from './data/recipes.json';
import Recipe from './Recipe';
const SortedRecipes = Recipes.sort((a, b) => a.Name.localeCompare(b.Name));

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    listroot: {
      width: 300,
      height: 600,
      maxWidth: 300,
      backgroundColor: theme.palette.background.paper,
    },
    root: {
      width: '100%',
    },
    recipe: {
      margin: theme.spacing(4),
    },
    grow: {
      flex: 1,
    },
  })
);
export default function RecipeList(props: { search: string }) {
  const classes = useStyles();
  const search = props.search.toLowerCase();
  const [selected, setSelected] = useState(0);
  const list = useMemo(() => {
    setSelected(-1);
    return SortedRecipes.filter((recipe) => recipe.Name.toLowerCase().includes(search));
  }, [search]);

  const renderRow = (rowProps: ListChildComponentProps) => {
    const { index, style } = rowProps;

    return (
      <ListItem button style={style} key={index} onClick={() => setSelected(index)}>
        <ListItemText primary={list[index].Name} />
      </ListItem>
    );
  };

  return (
    <Grid container direction="row" className={classes.root}>
      <Grid item className={classes.listroot}>
        <div>
          <FixedSizeList height={600} width={300} itemSize={40} itemCount={list.length}>
            {renderRow}
          </FixedSizeList>
        </div>
      </Grid>
      <Grid item className={classes.grow}>
        <div className={classes.recipe}>
          <Recipe recipe={list[selected]} />
        </div>
      </Grid>
    </Grid>
  );
}
